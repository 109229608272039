import React from "react"
import Layout from "@components/layout";
import {graphql, Link} from 'gatsby'

export default function Home({data}) {


  const listPosts = data.allContentfulPost.edges.map((post, idx) => (
    <article key={`post-${idx}`} className={`py-2`}>
        <Link to={`/${post.node.slug}`}><h3 className="text-lg">{post.node.title}</h3></Link>
        <small className="text-gray-700">{post.node.published}</small>
    </article>
  ));

  return (
    <Layout>
      <section className={`mx-auto max-w-2xl pb-8 space-y-8`}>
      <h1 className="text-4xl font-bold mb-20">Gonza's blog, travel, words and everything else</h1>
      <h2 className="text-2xl font-bold mb-10">Latest posts:</h2>
      {listPosts}
      </section>
    </Layout>
  )
  }


  export const query = graphql`
    query Posts {
      allContentfulPost(sort: {fields: published}) {
        edges {
          node {
            slug
            title
            published(formatString: "d/MM/yy")
          }
        }
      }
    }`;